<template>
  <v-card>
    <v-card-title class="text-h5">
      Email Analytics
    </v-card-title>
    <v-divider />
    <v-card-text>
      Download your Email Analytics Report for a selected date range.
    </v-card-text>
    <v-spacer />
    <v-card-actions class="flex-row-reverse">
      <v-dialog
        v-model="downloadFilesMenu"
        max-width="400px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            Download
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Select Date Range</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                  sm="8"
                  md="6"
                >
                  <v-menu
                    ref="dateMenu1"
                    v-model="dateMenu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-form ref="form">
                        <v-text-field
                          v-model="startDate"
                          label="Start date"
                          prepend-icon="mdi-calendar"
                          :rules="rules"
                          hide-details="auto"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </v-form>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="tertiary"
                        @click="dateMenu1 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="success"
                        @click="$refs.dateMenu1.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-menu
                    ref="dateMenu2"
                    v-model="dateMenu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End date"
                        prepend-icon="mdi-calendar"
                        required
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      no-title
                      scrollable
                      :allowed-dates="(endDate) => endDate <= new Date().toISOString().substr(0, 10)"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="tertiary"
                        @click="dateMenu2 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="success"
                        @click="$refs.dateMenu2.save(endDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <small>*Start and end date required</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="tertiary"
              :disabled="reportLoading"
              @click="resetDates()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success"
              :loading="reportLoading"
              :disabled="isDisabled"
              @click="getEmailAnalyticsReport()"
            >
              Download
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { azBlobService } from '@/shared/services'
import { downloadFileMixin } from '@/shared/mixins/general'

export default {
  name: 'EmailAnalyticsReport',

  mixins: [downloadFileMixin],

  data () {
    return {
      dateMenu1: false,
      dateMenu2: false,
      startDate: null,
      endDate: new Date().toISOString().substr(0, 10),
      reportLoading: false,
      downloadFilesMenu: false,
      results: null,
      rules: [
        value => !!value || 'Required.'
      ]
    }
  },

  computed: {
    ...mapGetters(['simulatedCustomerNowwId']),
    isDisabled () {
      return this.startDate === null
    }
  },

  methods: {
    async getEmailAnalyticsReport () {
      var _this = this
      this.reportLoading = true
      this.$store.commit('setLoadingMessage', 'Downloading')
      await azBlobService.downloadEmailAnalyticsReport({ customerId: this.$store.getters['simulatedCustomerNowwId'](), startDate: this.startDate, endDate: this.endDate })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.$_downloadFileMixin_downloadResponse(resp)
          }
        })
        .finally(() => {
          this.reportLoading = false
          this.resetDates()
        })
    },

    resetDates () {
      this.$refs.form.reset()
      this.endDate = new Date().toISOString().substr(0, 10)
      this.downloadFilesMenu = false
    }
  }
}

</script>
